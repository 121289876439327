// Some common variables that may be used all over the application
:root {
  --dice-size: 3.5rem; // Width and height of a render dice size
  --dice-gap: 0.2rem; // Common gap to leave between unraveled dice
  --dice-border-radius: 0.6rem; // Things that look like this are this much rounded

  --colour-mind: #7777ff;
  --colour-burly: #ff3333;
  --colour-delicate: #11ff11;
  --colour-armor: silver;

  --animation-factor: 1; // Used to change the speed of all sorts of animations
}

// Quotes should look kinda nice everywhere
blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
}

blockquote:before {
  color: #ccc;
  content: "“";
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.1em;
  vertical-align: -0.4em;
}

blockquote p {
  display: inline;
}

// Keyboard instructions shoud look kinda nice everywhere
kbd {
  background-color: #eee;
  border-radius: 3px;
  border: 1px solid #b4b4b4;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2),
    0 2px 0 0 rgba(255, 255, 255, 0.7) inset;
  color: #333;
  display: inline-block;
  font-size: 0.85em;
  font-weight: 700;
  line-height: 1;
  padding: 2px 4px;
  white-space: nowrap;
}

// Sometimes boxes need to be limited to a readable width
.readable-centered-box {
  max-width: 60rem;
  margin: 0px auto;
}

body {
  // We don't want borders on the actual game scenes
  padding: 0;
  margin: 0;

  // Vertical three column layout
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
